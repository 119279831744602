const constants = {
  ADD_MEDALS: "ADD_MEDALS",
  GAME_WON: "GAME_WON",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
};

export const actions = {
  addMedals: (data) => ({
    type: constants.ADD_MEDALS,
    data,
  }),
  gameWon: () => ({
    type: constants.GAME_WON,
  }),
  openModal: () => ({
    type: constants.OPEN_MODAL,
  }),
  closeModal: () => ({
    type: constants.CLOSE_MODAL,
  }),
};

// Reducer
export const defaultMedalsState = {
  medals: [],
  sections: [],
  isGameWon: false,
  openMedalsModal: false,
};

export default function(state = defaultMedalsState, action) {
  switch (action.type) {
    case constants.ADD_MEDALS:
      return {
        ...state,
        medals: [...state.medals, action.data],
        sections: [...new Set([...state.sections, action.data.section])],
      };
    case constants.GAME_WON:
      return {
        ...state,
        medals: [...state.medals, {}, {}, {}, {}],
        isGameWon: true,
      };
    case constants.OPEN_MODAL:
      return {
        ...state,
        openMedalsModal: true,
      };
    case constants.CLOSE_MODAL:
      return {
        ...state,
        openMedalsModal: false,
      };
    default:
      return state;
  }
}
